import React, { Fragment, useState, useEffect, useRef } from "react";
import css from "./noteList.module.css";
import jwt_decode from "jwt-decode";
import { CopyToClipboard } from "react-copy-to-clipboard";
import clipboard from "../../assets/clipboard.svg";
import axios from "axios";
// import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";

// TODO: implement ketonis rte with custom styling

// NOTE: react quill rte is commented out for V2-launch d/t issues with AI model and epic copy/paste functionality.

//Allows you to edit the patient and note associated with the patient.
const EditPatient = ({
  patient,
  patientsChange,
  setPatientsChange,
  org,
  isEditing,
  setIsEditing,
  name,
  generateRecordLink,
  status,
}) => {
  //when we edit the text we update the database
  const [sendTo4D, setSendTo4D] = useState(false);
  const [sendToOncoEMR, setSendToOncoEMR] = useState(false);
  const [sendToAthena, setSendToAthena] = useState(false);
  const [sendToModMed, setSendToModMed] = useState(false);
  const navigate = useNavigate();

  const redirectToSettings = () => {
    navigate("/settings");
  };

  const editText = async (id) => {
    try {
      const body = {
        description,
        note,
        LOSCode,
        codingInfo,
        encounterTime,
        timeBasedCode,
        icd_10_codes,
        orders,
        //patientData,
      };

      const myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("token", localStorage.token);

      await fetch(`${process.env.REACT_APP_API_URL}/notes/todos/${id}`, {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(body),
      });

      setPatientsChange(true);

      if (org === "4D EMR") {
        const confirmed = window.confirm(
          "Do you want to send this note to 4D EMR? You will no longer be able to edit it in Knowtex if you do. \n\n" +
            "Notes submitted same day are sent immediately. Notes submitted on subsequent days are synced twice daily at 7AM and 7PM. \n\n" +
            "Click OK to send the note to 4D EMR \n" +
            "Click Cancel to continue editing in Knowtex and send the note to 4D EMR later"
        );
        setSendTo4D(confirmed);
      }

      if (
        org === "cCARE" ||
        org === "Astera" ||
        org === "Astera Rheumatology" ||
        org === "Astera Breast Surgery" ||
        org === "Astera Pall Care" ||
        org === "Solara" ||
        org === "PCSRI" ||
        org === "CCC" ||
        org === "MOASD_V2" ||
        org === "MOASD_V3"
      ) {
        const confirmed = window.confirm(
          "Do you want to send this note to OncoEMR? You will no longer be able to edit it in Knowtex if you do. \n\n" +
            "Click OK to send the note to OncoEMR \n" +
            "Click Cancel to continue editing in Knowtex and send the note to OncoEMR later"
        );
        setSendToOncoEMR(confirmed);
        if (confirmed) {
          getAccessTokenAndSendNote();
        }
      }

      if (org === "MedVanta") {
        const confirmed = window.confirm(
          "Do you want to send this note to ModMed? You will no longer be able to edit it in Knowtex if you do. \n\n" +
            "Click OK to send the note to ModMed \n" +
            "Click Cancel to continue editing in Knowtex and send the note to ModMed later"
        );
        setSendToModMed(confirmed);
        if (confirmed) {
          getAccessTokenAndSendNote();
        }
      }

      if (org === "LVOBGYN") {
        const confirmed = window.confirm(
          "Do you want to send this note to Athena? You will no longer be able to edit it in Knowtex if you do. \n\n" +
            "Click OK to send the note to Athena \n" +
            "Click Cancel to continue editing in Knowtex and send the note to Athena later"
        );
        setSendToAthena(confirmed);
        if (confirmed) {
          getAccessTokenAndSendNote();
        }
      }

      // logging
      // get user_id from token
      const decoded_token = jwt_decode(localStorage.token);
      const user_id = decoded_token["user"]["id"];
      // User clicked upload
      window.gtag("event", "user_save_soap_note", {
        app_name: "Knowtex-Webapp",
        screen_name: "Note Edit Page",
        job_name: user_id,
      });

      // Define the event of when user clicked 'Save Changes'
      const saveNoteEvent = {
        env: process.env.REACT_APP_API_URL.includes("localhost")
          ? "local"
          : "prod",
        service: "knowtex-webApp",
        event: {
          eventName: "user_save_soap_note",
          userId: user_id,
          patientID: patient.patient_id,
          screenName: "Note Edit Page",
          timestamp: new Date().toISOString(),
        },
      };
      sendEvent(saveNoteEvent);
    } catch (err) {
      console.error(err.message);
    }
  };

  const getAccessTokenAndSendNote = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/dashboard/getaccesstoken`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        }
      );

      if (!response.ok) {
        console.log("can't get access token " + response);
        alert(
          "Unable to verify credentials to upload note. Please try again or contact Knowtex support to resolve this"
        );
        changeStatusToReview();
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      // Proceed to upload the note if access token is retrieved
      if (data.accessToken) {
        //console.log("access token " + data.accessToken);
        console.log("now about to send note");

        sendNoteViaCobalt(data.accessToken);
      } else {
        console.error("Access token not available.");
        alert(
          "Unable to verify credentials to upload note. Please try again or contact Knowtex support to resolve this"
        );
        changeStatusToReview();
      }
    } catch (error) {
      console.error("Failed to fetch access token:", error);
      changeStatusToReview();
    }
  };

  const sendNoteViaCobalt = async (accessToken) => {
    const apiUrl = "https://api.usecobalt.com/v1/notes";
    const bodyData = {
      appointment_id: patient.appointment_id,
      appointment_date: patient.date_of_appt,
      mrn: patient.mrn,
      note: note,
    };

    const headers = {
      "Content-Type": "application/json",
      client_id: "ci_live_RRG6m7sZGBWod2xfrhRkw4Ph",
      client_secret: "cs_live_iwoQAkjVRhao7xRbWLLdDUnu",
      access_token: accessToken,
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(bodyData),
      });

      if (!response.ok) {
        const data = await response.json();
        console.log("Can't upload note: " + data.message);

        alert(
          "Unable to upload note at this time. Please try again later or contact Knowtex support to resolve this. Error details: " +
            data.message
        );
        if (
          data.message ===
          "Account link expired. Please go through the integration setup again."
        ) {
          redirectToSettings();
        }
        changeStatusToReview();
        throw new Error(
          "Failed to send note, server response was not ok: " + data.message
        );
      }

      const responseData = await response.json();
      console.log("Response from Cobalt API:", responseData);
    } catch (error) {
      console.error("Error making request:", error);
      changeStatusToReview();
    }
  };

  const handleSendTo4D = async () => {
    if (!sendTo4D) return;

    changeStatusToLocked();

    setSendTo4D(false);
  };

  useEffect(() => {
    handleSendTo4D();
  }, [sendTo4D]);

  const handleSendToOncoEMR = async () => {
    if (!sendToOncoEMR) return;

    changeStatusToLocked();

    setSendToOncoEMR(false);
  };

  useEffect(() => {
    handleSendToOncoEMR();
  }, [sendToOncoEMR]);

  const handleSendToAthena = async () => {
    if (!sendToAthena) return;

    changeStatusToLocked();

    setSendToAthena(false);
  };

  useEffect(() => {
    handleSendToModMed();
  }, [sendToModMed]);

  const handleSendToModMed = async () => {
    if (!sendToAthena) return;

    changeStatusToLocked();

    setSendToModMed(false);
  };

  useEffect(() => {
    handleSendToAthena();
  }, [sendToAthena]);

  const changeStatusToLocked = async () => {
    try {
      const status = "Locked";
      const body = { status };

      const myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("token", localStorage.token);

      await fetch(
        `${process.env.REACT_APP_API_URL}/notes/status/${patient.patient_id}`,
        {
          method: "PUT",
          headers: myHeaders,
          body: JSON.stringify(body),
        }
      );
      //console.log("Status changed");
    } catch (err) {
      console.error(err.message);
    }
  };

  const changeStatusToReview = async () => {
    try {
      const status = "Review";
      //console.log(status);
      const body = { status };

      const myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("token", localStorage.token);

      await fetch(
        `${process.env.REACT_APP_API_URL}/notes/status/${patient.patient_id}`,
        {
          method: "PUT",
          headers: myHeaders,
          body: JSON.stringify(body),
        }
      );
      //console.log("Status changed");
    } catch (err) {
      console.error(err.message);
    }
  };

  // Function to send the defined even to aws
  function sendEvent(userEvent) {
    // Replace "http://example.com/api/events" with the actual endpoint URL.
    const endpointUrl =
      "https://tpv4xmll56.execute-api.us-east-1.amazonaws.com/prod/doctorservice";

    axios
      .post(endpointUrl, userEvent)
      .then((response) => {
        //console.log("Event sent successfully:", response.data);
        //console.log("Event: %v",userEvent);
      })
      .catch((error) => {
        //console.error("Failed to send event:", error);
      });
  }

  // Function to send log when user clicked 'Review Note'
  function log_review_note(patient_id) {
    // get user_id from token
    const decoded_token = jwt_decode(localStorage.token);
    const user_id = decoded_token["user"]["id"];

    const reviewNoteEvent = {
      env: process.env.REACT_APP_API_URL.includes("localhost")
        ? "local"
        : "prod",
      service: "knowtex-webApp",
      event: {
        eventName: "user_reviewed_soap_note",
        userId: user_id,
        patientID: patient_id,
        screenName: "Note Edit Page",
        timestamp: new Date().toISOString(),
      },
    };
    // send the event
    sendEvent(reviewNoteEvent);
  }

  async function onCopy(patient_id) {
    setIsCopied(true);
    const decoded_token = jwt_decode(localStorage.token);
    const user_id = decoded_token["user"]["id"];
    const copyNoteEvent = {
      env: process.env.REACT_APP_API_URL.includes("localhost")
        ? "local"
        : "prod",
      service: "knowtex-webApp",
      event: {
        eventName: "user_clicked_copy",
        userId: user_id,
        patientID: patient_id,
        screenName: "Note Edit Page",
        timestamp: new Date().toISOString(),
      },
    };

    sendEvent(copyNoteEvent);
  }

  const formatPatientData = (data) => {
    if (!data) return "";

    const fields = {
      patient_name: "Patient Name",
      past_medical_history: "Past Medical History",
      past_surgical_history: "Past Surgical History",
      medications: "Medications",
      allergies: "Allergies",
      gender: "Gender",
      date_of_birth: "Date of Birth",
    };

    return Object.entries(fields)
      .map(([key, title]) => {
        return `${title}: ${data[key] || ""}`;
      })
      .join("\n");
  };
  const [description, setDescription] = useState(patient.description);
  const [note, setNote] = useState(patient.note);
  const [isCopied, setIsCopied] = useState(false);
  const [codingInfo, setCodingInfo] = useState(patient.coding_information);
  const [LOSCode, setLOSCode] = useState(patient.los_code);
  const [encounterTime, setEncounterTime] = useState(patient.time_spent);
  const [timeBasedCode, setTimeBasedCode] = useState(patient.time_based_code);
  const [icd_10_codes, setICD_10_codes] = useState(patient.icd_10_codes);
  const [orders, setOrders] = useState(patient.orders);
  const [patientData, setPatientData] = useState(
    formatPatientData(patient.patient_data)
  );

  const [diarizedTranscript, setDiarizedTranscript] = useState(
    patient.diarized_transcript
  );
  const [diarizedTranscriptCsvUrl, setDiarizedTranscriptCsvUrl] = useState(
    patient.diarized_transcript_csv_url
  );

  const quillRef = useRef();

  const isLocked = patient.status === "Locked";

  const convertPlainTextToHtml = (text) => {
    // Check if text is truthy before attempting to replace
    return text ? text.replace(/\n/g, "<br>") : "";
  };
  const responseHtml = convertPlainTextToHtml(note);

  // const quillModules = {
  //   toolbar: [
  //     [{ header: [1, 2, 3, false] }],
  //     ["bold", "italic", "underline", "strike"],
  //     [{ list: "ordered" }, { list: "bullet" }],
  //     [{ align: [] }],
  //     [{ color: [] }, { background: [] }],
  //     ["clean"],
  //   ],
  // };

  //useEffect to handle csv download
  useEffect(() => {
    setDiarizedTranscriptCsvUrl(patient.diarized_transcript_csv_url);
  }, [patient.diarized_transcript_csv_url]);

  const handleTranscriptDownload = () => {
    if (diarizedTranscriptCsvUrl) {
      //create anchor element
      const link = document.createElement("a");
      link.href = diarizedTranscriptCsvUrl;

      // set download attribute with csv file name
      link.download = `${diarizedTranscriptCsvUrl}`;

      // append anchor element to body
      document.body.appendChild(link);

      //trigger click event
      link.click();

      //clean up
      document.body.removeChild(link);
    }
  };

  const codesCount = icd_10_codes?.match(/([A-Z]\d+\.\d+)/g)?.length;
  const ordersCount = orders?.match(/([t][e][s][t]\_[n][a][m][e])/g)?.length;

  return (
    <Fragment>
      <div
        role="edit-patient"
        className="flex items-center hover:text-secondary-text-grey"
      >
        <div className="pr-2">
          <svg
            className="w-6 h-6 text-background1"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 4h3c.6 0 1 .4 1 1v15c0 .6-.4 1-1 1H6a1 1 0 01-1-1V5c0-.6.4-1 1-1h3m0 3h6m-6 7l2 2 4-4m-5-9v4h4V3h-4z"
            />
          </svg>
        </div>

        {/* Render the button differently based on the status */}
        <button
          type="button"
          className={`text-xl ${
            status === "Review" ? "" : "flex items-center text-ktx-red"
          }`}
          data-bs-toggle="modal"
          data-bs-target={`#id${patient.patient_id}`}
          onClick={() => {
            setDescription(patient.description);
            setNote(patient.note);
            setPatientData(patientData);
            setCodingInfo(patient.coding_information);
            setLOSCode(patient.los_code);
            log_review_note(patient.patient_id);
            setEncounterTime(patient.time_spent);
            setTimeBasedCode(patient.time_based_code);
            setICD_10_codes(patient.icd_10_codes);
            setOrders(patient.orders);
            setIsEditing(status !== "Locked"); // Set editing based on status
            setDiarizedTranscript(patient.diarized_transcript);
            setDiarizedTranscriptCsvUrl(patient.diarized_transcript_csv_url);
          }}
        >
          {status === "Review" ? "Review" : "Locked (View Only)"}
        </button>
      </div>

      <div
        className="modal fade modal-xl h-full"
        id={`id${patient.patient_id}`}
        tabIndex="-1"
        aria-labelledby="editModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        role="edit-modal"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="text-accent1 text-3xl font-bold"
                id="editModalLabel"
              >
                Review {patient.description}'s Note
              </h1>
              <button
                type="button"
                className="mt-0 mb-3"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setDescription(patient.description);
                  setNote(patient.note);
                  setCodingInfo(patient.coding_information);
                  setLOSCode(patient.los_code);
                  log_review_note(patient.patient_id);
                  setEncounterTime(patient.time_spent);
                  setTimeBasedCode(patient.time_based_code);
                  setICD_10_codes(patient.icd_10_codes);
                  setOrders(patient.orders);
                  setPatientData(patientData);
                  setIsCopied(false);
                  // set isEditing back to false on close button
                  setIsEditing(false);
                  setDiarizedTranscript(patient.diarized_transcript);
                  setDiarizedTranscriptCsvUrl(
                    patient.diarized_transcript_csv_url
                  );
                }}
              >
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18 17.94 6M18 18 6.06 6"
                  />
                </svg>
              </button>
            </div>
            <div className="modal-body ">
              <div>
                <nav>
                  <div
                    className="text-black flex mb-0 whitespace-nowrap overflow-x-auto "
                    id={`id${patient.patient_id}nav-tab`}
                    role="tablist"
                  >
                    <button
                      className=" nav-link active border-light-grey pt-2 pl-11 pr-11 pb-2 mr-4  bg-secondary-grey rounded-t-md focus:bg-accent1 focus:text-background1 text-accent1 font-bold"
                      id={`id${patient.patient_id}nav-home-tab`}
                      data-bs-toggle="tab"
                      data-bs-target={`#id${patient.patient_id}nav-home`}
                      type="button"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      Note
                    </button>
                    <button
                      className="nav-link border pt-2 pl-11 pr-11 pb-2 mr-4 rounded-t-md  focus:bg-accent1 focus:text-background1 text-accent1 bg-secondary-grey font-bold"
                      id={`id${patient.patient_id}nav-profile-tab`}
                      data-bs-toggle="tab"
                      data-bs-target={`#id${patient.patient_id}nav-profile`}
                      type="button"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                    >
                      Codes ({codesCount ? codesCount : 0})
                    </button>
                    <button
                      className="nav-link border pt-2 pl-11 pr-11 pb-2 mr-4 rounded-t-md focus:bg-accent1 focus:text-background1 text-accent1  bg-secondary-grey font-bold"
                      id={`id${patient.patient_id}nav-orders-tab`}
                      data-bs-toggle="tab"
                      data-bs-target={`#id${patient.patient_id}nav-orders`}
                      type="button"
                      role="tab"
                      aria-controls="nav-orders"
                      aria-selected="false"
                    >
                      Orders ({ordersCount ? ordersCount : 0})
                    </button>
                    {/* TODO: remove the cCare conditional, just having it here so it renders. */}

                    <button
                      className="nav-link border pt-2 pl-11 pr-11 pb-2 mr-4 rounded-t-md focus:bg-accent1 focus:text-background1 text-accent1  bg-secondary-grey font-bold"
                      id={`id${patient.patient_id}nav-diarized-tab`}
                      data-bs-toggle="tab"
                      data-bs-target={`#id${patient.patient_id}nav-diarized`}
                      type="button"
                      role="tab"
                      aria-controls="nav-diarized"
                      aria-selected="false"
                    >
                      Transcript
                    </button>
                  </div>
                </nav>
                <div
                  className="tab-content p-4 border-t-2 border-t-accent1 border-x-2  border-x-transparent-grey"
                  id="nav-tabContent"
                >
                  <div
                    className="tab-pane fade show active"
                    id={`id${patient.patient_id}nav-home`}
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                  >
                    <div className="float-end d-flex mt-3 mb-3">
                      <CopyToClipboard
                        // text={responseHtml || ""}
                        // options={{ format: "text/html" }}
                        text={note}
                        onCopy={() => onCopy(patient.patient_id)}
                      >
                        {isCopied ? (
                          <div className="text-success">Copied</div>
                        ) : (
                          <div className="flex ">
                            <img
                              src={clipboard}
                              width="20"
                              height="20"
                              alt="copy"
                              className="mr-1 hover:cursor-pointer"
                            />
                            <h1 className="text-accent1 hover:cursor-pointer">
                              Copy Note
                            </h1>
                          </div>
                        )}
                      </CopyToClipboard>
                    </div>
                    <div className="mt-5">
                      <textarea
                        type="text"
                        readOnly={status === "Locked"} // Apply readOnly based on status
                        className="form-control resize-none rounded-md border-0 shadow-knowtex-shadow text-accent1 bg-secondary-grey"
                        value={note || ""}
                        onChange={(e) => {
                          setNote(e.target.value);
                          setIsCopied(false);
                        }}
                        rows="30"
                      ></textarea>
                      {/* <ReactQuill
                        theme="snow"
                        value={responseHtml || ""}
                        onChange={(newNote) => {
                          setNote(newNote);
                          setIsCopied(false);
                        }}
                        className="text-accent1 "
                        modules={quillModules}
                        ref={quillRef}
                        formats={[
                          "header",
                          "bold",
                          "italic",
                          "underline",
                          "strike",
                          "list",
                          "bullet",
                          "align",
                          "color",
                          "background",
                        ]}
                        readOnly={isLocked}
                      /> */}
                      {/* {name === "Dr. Ketonis" ? (
                        <KetonisRTE />
                      ) : (
                        <ReactQuill
                          theme="snow"
                          value={responseHtml || ""}
                          onChange={(newNote) => {
                            setNote(newNote);
                            setIsCopied(false);
                          }}
                          className="text-accent1 "
                          modules={quillModules}
                          ref={quillRef}
                          formats={[
                            "header",
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "list",
                            "bullet",
                            "align",
                            "color",
                            "background",
                          ]}
                        />
                      )} */}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade text-accent1 text-xl font-bold"
                    id={`id${patient.patient_id}nav-profile`}
                    role="tabpanel"
                    aria-labelledby="nav-profile-tab"
                  >
                    ICD-10 Codes
                    <textarea
                      type="text"
                      readOnly={status === "Locked"}
                      className="form-control resize-none rounded-md border-0 mb-4 mt-2 shadow-knowtex-shadow bg-secondary-grey"
                      value={icd_10_codes || ""}
                      onChange={(e) => setICD_10_codes(e.target.value)}
                      rows="10"
                    ></textarea>
                    Medical Decision Making Analysis
                    <textarea
                      type="text"
                      readOnly={status === "Locked"}
                      className="form-control resize-none mb-4 mt-2 rounded-md border-0 shadow-knowtex-shadow bg-secondary-grey"
                      value={codingInfo || ""}
                      onChange={(e) => setCodingInfo(e.target.value)}
                      rows="30"
                    ></textarea>
                    <p></p>
                    E&M Code (based on MDM)
                    <input
                      type="text"
                      readOnly={status === "Locked"}
                      className="form-control mb-4 mt-2 rounded-md border-0 shadow-knowtex-shadow bg-secondary-grey"
                      value={LOSCode || ""}
                      onChange={(e) => setLOSCode(e.target.value)}
                    ></input>
                    Total Encounter Time
                    <input
                      type="text"
                      readOnly={status === "Locked"}
                      className="form-control mb-4 mt-2 rounded-md border-0 shadow-knowtex-shadow bg-secondary-grey"
                      value={Math.floor(encounterTime) || ""} //convert from secs to min
                      onChange={(e) => setEncounterTime(e.target.value)}
                    ></input>
                    E&M Code (based on Time)
                    <input
                      type="text"
                      readOnly={status === "Locked"}
                      className="form-control mb-4 mt-2 rounded-md border-0 shadow-knowtex-shadow bg-secondary-grey"
                      value={timeBasedCode || ""}
                      onChange={(e) => setTimeBasedCode(e.target.value)}
                    ></input>
                  </div>
                  <div
                    className="tab-pane fade text-accent1 text-xl font-bold"
                    id={`id${patient.patient_id}nav-orders`}
                    role="tabpanel"
                    aria-labelledby="nav-orders-tab"
                  >
                    Orders
                    <textarea
                      type="text"
                      readOnly={status === "Locked"} // Apply readOnly based on status
                      className="form-control resize-none mb-4 mt-2 rounded-md border-0 shadow-knowtex-shadow bg-secondary-grey"
                      value={orders || ""}
                      onChange={(e) => setOrders(e.target.value)}
                      rows="10"
                    ></textarea>
                  </div>
                  <div
                    className="tab-pane fade text-accent1 text-xl font-bold "
                    id={`id${patient.patient_id}nav-diarized`}
                    role="tabpanel"
                    aria-labelledby="nav-diarized=transcript-tab"
                  >
                    {/* <button
                        className="float-end mb-3 rounded-lg  text-accent1 p-2 text-sm mr-5 flex items-center"
                        onClick={handleTranscriptDownload}
                        disabled={!diarizedTranscriptCsvUrl}
                      >
                        <svg
                          class="w-6 h-6 text-accent1 dark:text-white mr-1"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M12 13V4M7 14H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2m-1-5-4 5-4-5m9 8h.01"
                          />
                        </svg>
                        Download Transcript CSV
                      </button> */}
                    Transcript
                    <textarea
                      type="text"
                      readOnly={status === "Locked"}
                      className="form-control resize-none mb-4 mt-2 rounded-md border-0 shadow-knowtex-shadow bg-secondary-grey"
                      value={diarizedTranscript || ""}
                      rows={30}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {status === "Review" ? generateRecordLink(patient) : null}

              {status === "Review" ? (
                <button
                  type="button"
                  className="bg-transparent-grey text-knowtex-grey pl-10 pr-10 pt-2 pb-2 rounded-md font-medium mr-4"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setDescription(patient.description);
                    setNote(patient.note);
                    setCodingInfo(patient.coding_information);
                    setLOSCode(patient.los_code);
                    setPatientData(patientData);
                    log_review_note(patient.patient_id);
                    setEncounterTime(patient.time_spent);
                    setTimeBasedCode(patient.time_based_code);
                    setICD_10_codes(patient.icd_10_codes);
                    setOrders(patient.orders);
                    setIsCopied(false);
                    setDiarizedTranscript(patient.diarized_transcript);
                    setDiarizedTranscriptCsvUrl(
                      patient.diarized_transcript_csv_url
                    );

                    // set isEditing back to false on close button
                    setIsEditing(false);
                  }}
                >
                  Cancel
                </button>
              ) : null}

              {(org === "cCARE" ||
                org === "Astera" ||
                org === "Astera Rheumatology" ||
                org === "Astera Breast Surgery" ||
                org === "Astera Pall Care" ||
                org === "Solara" ||
                org === "PCSRI" ||
                org === "CCC" ||
                org === "MOASD_V3" ||
                org === "MOASD_V2") &&
                patient.status !== "Locked" && (
                  <button
                    type="button"
                    //disabled={uploadToCCareLoading || patient.status === "Locked"}
                    // className={
                    //   uploadToCCareLoading
                    //     ? "bg-transparent-grey text-background1 cursor-not-allowed"
                    //     : css.save
                    // }
                    data-bs-dismiss="modal"
                    className={css.oncobutton}
                    onClick={() => {
                      editText(patient.patient_id);
                      //uploadToCCare();
                      setIsCopied(false);
                      // set isEditing back to false on close button
                      setIsEditing(false);
                    }}
                    disabled={patient.status === "Locked"}
                  >
                    Upload to OncoEMR
                  </button>
                )}

              {org === "4D EMR" && patient.status !== "Locked" && (
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  className={css.oncobutton}
                  onClick={() => {
                    editText(patient.patient_id);
                    //uploadToCCare();
                    setIsCopied(false);
                    // set isEditing back to false on close button
                    setIsEditing(false);
                  }}
                  disabled={patient.status === "Locked"}
                >
                  Upload to 4D EMR
                </button>
              )}

              {org === "LVOBGYN" && patient.status !== "Locked" && (
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  className={css.oncobutton}
                  onClick={() => {
                    editText(patient.patient_id);
                    //uploadToCCare();
                    setIsCopied(false);
                    // set isEditing back to false on close button
                    setIsEditing(false);
                  }}
                  disabled={patient.status === "Locked"}
                >
                  Upload to Athena
                </button>
              )}

              {org === "MedVanta" && patient.status !== "Locked" && (
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  className={css.oncobutton}
                  onClick={() => {
                    editText(patient.patient_id);
                    //uploadToCCare();
                    setIsCopied(false);
                    // set isEditing back to false on close button
                    setIsEditing(false);
                  }}
                  disabled={patient.status === "Locked"}
                >
                  Upload to ModMed
                </button>
              )}

              {status === "Review" ? (
                <button
                  type="button"
                  className={css.save}
                  data-bs-dismiss="modal"
                  onClick={() => {
                    editText(patient.patient_id);
                    setIsCopied(false);
                    // set isEditing back to false on close button
                    setIsEditing(false);
                  }}
                  disabled={patient.status === "Locked"}
                >
                  Save Changes
                </button>
              ) : (
                <button
                  type="button"
                  className={css.save}
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setDescription(patient.description);
                    setNote(patient.note);
                    setPatientData(patientData);
                    setCodingInfo(patient.coding_information);
                    setLOSCode(patient.los_code);
                    log_review_note(patient.patient_id);
                    setEncounterTime(patient.time_spent);
                    setTimeBasedCode(patient.time_based_code);
                    setICD_10_codes(patient.icd_10_codes);
                    setOrders(patient.orders);
                    setIsCopied(false);
                    setDiarizedTranscript(patient.diarized_transcript);
                    setDiarizedTranscriptCsvUrl(
                      patient.diarized_transcript_csv_url
                    );

                    // set isEditing back to false on close button
                    setIsEditing(false);
                  }}
                >
                  Done
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditPatient;
